.section_reunion {
    margin-top: calc(var(--column-gap) * 1.5);
}
.section_reunion__header {
    background-color: #08FFC1;
    color: #000;
}
.section_reunion__item .section-item__name {
    text-align: center;
}


/* Айдентика */
.section_reunion__item_main {
    background: #0c319a;
    padding: 5rem calc(var(--column-gap) + var(--column-width)) 3rem;
    overflow: hidden;
    position: relative;
    display: block;
}
.section_reunion__item_main:after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(45deg, #102350, transparent);
    mix-blend-mode: overlay;
}
.section_reunion__item_main video {
    width: 33%;
    margin: -5rem auto;
    display: block;
    transition: all .3s ease;
}
.section_reunion__item_main:hover img {
    transform: scale(1.03);
}
.section_reunion__item_main .section-item__name {
    margin: 0 auto;
    text-align: center;
    max-width: 16em;
    color: #ffffff;
}



@media (max-width: 800px) {
    .section_reunion__item_main video {
        width: 100%;
    }
}