.section_ai__header {
    background: #023;
    color: white;
    background-size: cover;
}
.ai-patterns__cover {
    background-size: 50%;
    background-repeat: repeat;
    background-position: center 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vw;
    padding-bottom: 0;
}

.route_ai-patterns .block_image-variants__header sup {
    color: #737373;
    font-size: 70%;
    margin-left: -0.33rem;
}

.route_ai-patterns .block_image-variants__content-item {
    background-repeat: repeat;
    background-position: center;
    background-size: 18%;
}

.ai-patterns__imperial .block_image-variants__content-item {
    background-size: 18%;

}
.ai-patterns__biology .block_image-variants__content-item {
    background-size: 35%;
}


