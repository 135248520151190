.section_geekbrains {
    color: #ffffff;
}
.section_geekbrains__header {
    background: #000000;
    color: #ffffff;
    position: relative;
    z-index: 2;
}
.section_geekbrains__item_main {
    background: radial-gradient(37.04% 82.13% at 81% 51.98%, rgba(63, 183, 190, 0.5) 0%, rgba(63, 183, 190, 0.358073) 18.75%, rgba(63, 183, 190, 0.216146) 42.71%, rgba(63, 183, 190, 0.0943182) 69.27%, rgba(63, 183, 190, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(213.26deg, #021824 13.35%, #083047 86.34%);
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-gap: var(--column-gap);
    grid-template-areas: ". A A A A A A A A . . . . .";
    padding: 3rem 0;
    position: relative;
}
.section_geekbrains__item-main-inner {
    grid-area: A;
}

.section_geekbrains__image {
    transition: all .3s ease;
}
.section_geekbrains:hover .section_geekbrains__image {
    filter: hue-rotate(-75deg);
}
.section_geekbrains__lead {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 500;
    margin-top: 1rem;
}
.section_geekbrains__text {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-top: 1rem;
}
.section_geekbrains__student {
    position: absolute;
    bottom: 0;
    left: 66%;
    height: 120%;
}

@media (max-width: 800px) {
    .section_geekbrains {
        margin: 1.5rem 0;
    }
    .section_geekbrains__item_main {
        overflow: hidden;
    }
    .section_geekbrains__student {
        left: 50%;
        height: 65%;
    }
    .section_geekbrains__item-main-inner {
        position: relative;
        z-index: 2;
    }
}