.superjob-identity__cover {
    margin-top: 0;
}

.route_superjob-identity__roles {
    background: radial-gradient(157.06% 103.91% at 145.63% 77.04%, rgba(2, 156, 128, 0.6) 0%, rgba(1, 39, 84, 0.25625) 57.29%, rgba(8, 0, 31, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(251.38% 192.39% at -22.24% 0%, rgba(10, 112, 87, 0.6) 0%, rgba(5, 32, 72, 0.28125) 53.13%, rgba(8, 0, 56, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(79.27% 80.97% at 11.02% 100%, rgba(0, 48, 38, 0.2) 0%, rgba(0, 8, 38, 0.0927083) 53.65%, rgba(10, 0, 37, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(55.2% 157.75% at 100% 43.38%, rgba(0, 113, 106, 0.2) 0%, rgba(0, 15, 53, 0.0833333) 58.33%, rgba(4, 0, 10, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #012222;
    color: #ffffff;
}
.route_superjob-identity__roles a {
    color: #ffffff;
    text-decoration-color: #ffffff66;
}

.route_superjob-identity__roles a:hover {
    text-decoration-color: #ffffff;
}

@media (max-width: 800px) { 
    .superjob-identity__vk .block_image__caption {
        margin-left: -12.5%;
        margin-right: -12.5%;
        width: 125%;
    }
}