.block_image-change {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    margin: 2rem 0;
}

.block_image-change > * { 
    position: absolute; 
    width: 100%; 
    transition: all .3s ease;
    opacity: 1;
}

.block_image-change.show1 > *:nth-child(n+2) { opacity: 0 }
.block_image-change.show2 > *:nth-child(n+3) { opacity: 0 }
.block_image-change.show3 > *:nth-child(n+4) { opacity: 0 }
.block_image-change.show4 > *:nth-child(n+5) { opacity: 0 }
.block_image-change.show5 > *:nth-child(n+6) { opacity: 0 }
.block_image-change.show6 > *:nth-child(n+7) { opacity: 0 }
.block_image-change.show7 > *:nth-child(n+8) { opacity: 0 }
.block_image-change.show8 > *:nth-child(n+9) { opacity: 0 }
.block_image-change.show9 > *:nth-child(n+10) { opacity: 0 }
.block_image-change.show10 > *:nth-child(n+11) { opacity: 0 }
.block_image-change.show11 > *:nth-child(n+12) { opacity: 0 }
.block_image-change.show12 > *:nth-child(n+13) { opacity: 0 }
.block_image-change.show13 > *:nth-child(n+14) { opacity: 0 }
.block_image-change.show14 > *:nth-child(n+15) { opacity: 0 }
.block_image-change.show15 > *:nth-child(n+16) { opacity: 0 }
.block_image-change.show16 > *:nth-child(n+17) { opacity: 0 }
.block_image-change.show17 > *:nth-child(n+18) { opacity: 0 }
.block_image-change.show18 > *:nth-child(n+19) { opacity: 0 }
.block_image-change.show19 > *:nth-child(n+20) { opacity: 0 }

@media (max-width: 800px) {
    .block_image-change {
        margin: 1.5rem 0;
    }
}