.section_superjob {
    margin-top: calc(var(--column-gap) * 1.5);
}
.section_superjob__header {
    background-color: #08FFC1;
    color: #000;
}
.section_superjob__item .section-item__name {
    text-align: center;
}
.grid-wrapper_superjob {
    display: grid;
    grid-template-areas: 
        "A A A A A A A A A A A A A A" 
        ". B B B B B C C C C C C C ." 
        ". B B B B B D D D D G G G ." 
        ". F F F F F D D D D G G G .";
    grid-gap: var(--column-gap);
    align-items: start;
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: max-content auto min-content 1fr min-content;
}
.section_superjob__item {
    align-self: start;
}


/* Айдентика (главная) */
.section_superjob__item_main {
    grid-area: A;
    background: radial-gradient(157.06% 103.91% at 145.63% 77.04%, rgba(2, 156, 128, 0.6) 0%, rgba(1, 39, 84, 0.25625) 57.29%, rgba(8, 0, 31, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(251.38% 192.39% at -22.24% 0%, rgba(10, 112, 87, 0.6) 0%, rgba(5, 32, 72, 0.28125) 53.13%, rgba(8, 0, 56, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(79.27% 80.97% at 11.02% 100%, rgba(0, 48, 38, 0.2) 0%, rgba(0, 8, 38, 0.0927083) 53.65%, rgba(10, 0, 37, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(55.2% 157.75% at 100% 43.38%, rgba(0, 113, 106, 0.2) 0%, rgba(0, 15, 53, 0.0833333) 58.33%, rgba(4, 0, 10, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #012222;
    padding: 5rem calc(var(--column-gap) + var(--column-width)) 3rem;
}
.section_superjob__item_main img {
    width: 100%;
    margin-bottom: 2rem;
    transition: all .3s ease;
}
.section_superjob__item_main:hover img {
    transform: scale(1.03);
}
.section_superjob__item_main .section-item__name {
    margin: 0 auto;
    text-align: center;
    max-width: 16em;
    color: #ffffff;
}


/* Айдентика и продукты суббрендов */
.section_superjob__item_subbrands {
    grid-area: B;
}
.section_superjob__item_subbrands .section-item__image {
    height: 0;
    padding-bottom: 100%;
    border-radius: 50%;
    overflow: visible;
    background: linear-gradient(180deg, #ADFFEB 0%, #17DFB6 50%, #01B896 100%);
    transition: all .3s ease;
    position: relative;
}
.section_superjob__item_subbrands .section-item__image img {
    position: absolute;
    transition: all .3s ease;
}
.section_superjob__item_subbrands .section-item__image img:nth-child(1) {
    width: 86%;
    left: 16%;
    top: -9%;
}
.section_superjob__item_subbrands .section-item__image img:nth-child(2) {
    width: 79%;
    top: 28%;
    left: 30%;
}
.section_superjob__item_subbrands .section-item__image img:nth-child(3) {
    width: 100%;
    left: -21%;
    top: -6%;
}
.section_superjob__item_subbrands:hover .section-item__image img {
    transform: scale(1.05)
}


/* 1000 и 1 улучшение интерфейсов */
.section_superjob__item_interface {
    grid-area: C;
}
.section_superjob__item_interface .section-item__image {
    position: relative;
    height: 0;
    padding-bottom: 58%;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #CEDCD900 0%, #CEDCD9 10%, #CEDCD9 90%, #CEDCD900 100%);
    border-image-slice: 1;
    border-top: 0;
}
.section_superjob__item_interface .section-item__image img {
    position: absolute;
    bottom: 0;
    transition: all .3s ease;
}
.section_superjob__item_interface .section-item__image img:nth-child(1) {
    height: 80%;
    left: 6%
}
.section_superjob__item_interface .section-item__image img:nth-child(2) {
    height: 100%;
    left: 22%;
}
.section_superjob__item_interface .section-item__image img:nth-child(3) {
    height: 70%;
    left: 68%;
}
.section_superjob__item_interface:hover .section-item__image img:nth-child(1) {
    left: 8%;
}
.section_superjob__item_interface:hover .section-item__image img:nth-child(2) {
    height: 105%;
}
.section_superjob__item_interface:hover .section-item__image img:nth-child(3) {
    left: 66%;
}


/* 8 рекламных кампаний */
.section_superjob__item_elevator {
    grid-area: D;
}
.section_superjob__item_elevator .section-item__image {
    position: relative;
    overflow: hidden;
    border-radius: 2px;
}
.section_superjob__item_elevator .item_elevator__left-door {
    position: absolute;
    height: 81%;
    bottom: 0;
    right: 50%;
    transition: all .8s ease;
}
.section_superjob__item_elevator:hover .item_elevator__left-door {
    right: 75%;
}
.section_superjob__item_elevator .item_elevator__right-door {
    position: absolute;
    height: 81%;
    bottom: 0;
    left: 50%;
    transition: all .8s ease;
}
.section_superjob__item_elevator:hover .item_elevator__right-door {
    left: 75%;
}
.section_superjob__item_elevator .item_elevator__outer {
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;    
}



/* Продукт */
.section_superjob__item_product {
    grid-area: E;
    background-color: #E8F6F0;
    border-radius: 50%;
    height: 0;
    padding-bottom: calc(100% + 0.5rem);
    margin: -0.25rem -0.25rem -0.25rem -0.25rem;
}
.section__item.section_superjob__item_product .section-item__name {
    -webkit-font-feature-settings: "smcp";
    font-feature-settings: "smcp";
    font-size: 1.1rem;
    font-variant: small-caps;
    letter-spacing: .06em;
    line-height: 1.25;
    margin-left: 26.2%;
    margin-top: -6%;
    text-align: left;
    width: 9rem;
    font-weight: 500;
}
.section_superjob__item_product .section-item__image {
    width: 75%;
    margin-top: -13%;
    margin-left: 5%;
}
.section__item.section_superjob__item.section_superjob__item_product .section-item__name {
    font-weight: 400;
}



/* Шаблоны лендингов */
.section_superjob__item_landings {
    grid-area: F;
}
.section_superjob__item_landings .section-item__image {
    position: relative;
    overflow: visible;
    height: fit-content;
}
.section_superjob__item_landings .section_superjob__landing_browser {
    box-shadow: 0 1rem 2rem -1rem rgb(0 2 45 / 55%);
}
.item_landings__icon-list {
    position: absolute;
    left: -10%;
    right: -10%;
    top: 0;
    bottom: 0;
    overflow: hidden;
}
.item_landings__icon-list img {
    position: absolute;
    transition: all .3s ease;
}
.section_superjob__item_landings .section_superjob__landing_woman {
    width: 45%;
    bottom: 0;
    left: 22%; 
    filter: saturate(1.4);
}
.section_superjob__item_landings:hover .section_superjob__landing_woman {
    filter: saturate(1.5) brightness(1.1);
}
.section_superjob__item_landings .section_superjob__landing_icon_bag { 
    width: 25%;
    left: 23%;
    bottom: -11%;
}
.section_superjob__item_landings:hover .section_superjob__landing_icon_bag { 
    width: 26%;
    left: 21%;
    bottom: -14%;
    filter: blur(.03rem);
}
.section_superjob__item_landings .section_superjob__landing_icon_coin { 
    width: 24%;
    left: 79%;
    top: 50%;
    filter: blur(0.05rem);
}
.section_superjob__item_landings:hover .section_superjob__landing_icon_coin { 
    width: 26%;
    left: 80%;
    top: 50%;
    filter: blur(0.07rem);
}
.section_superjob__item_landings .section_superjob__landing_icon_funnel { 
    width: 42%;
    left: 0%;
    top: 20%;
}
.section_superjob__item_landings:hover .section_superjob__landing_icon_funnel { 
    width: 43%;
    left: -3%;
    top: 19%;
}
.section_superjob__item_landings .section_superjob__landing_icon_glasses {
    width: 20%;
    top: 25%;
    left: 54%;
    opacity: .7;
    filter: blur(0.03rem);
}
.section_superjob__item_landings:hover .section_superjob__landing_icon_glasses {
    width: 21%;
    top: 24%;
    left: 55%;
    opacity: .9;
    filter: blur(0);
}
.section_superjob__item_landings .section_superjob__landing_icon_heart { 
    width: 30%;
    bottom: -23%;
    left: 55%;
    filter: blur(0.075rem);
}
.section_superjob__item_landings:hover .section_superjob__landing_icon_heart { 
    width: 25%;
    bottom: -20%;
    left: 58%;
    filter: blur(0.02rem);
}
.section_superjob__item_landings .section_superjob__landing_icon_letter { 
    width: 32%;
    top: 33%;
    left: 65%;
    opacity:.85;
    filter: blur(0.02rem);
}
.section_superjob__item_landings:hover .section_superjob__landing_icon_letter { 
    width: 30%;
    top: 34%;
    left: 65%;
    opacity:.75;
    filter: blur(0.04rem);
}
.section_superjob__item_landings .section_superjob__landing_icon_phone {
    width: 23%;
    left: -2%;
    top: 60%;
}
.section_superjob__item_landings:hover .section_superjob__landing_icon_phone {
    width: 22%;
    left: 0;
    top: 63%;
    filter: blur(0.04rem);
}




/* Еще */
.section_superjob__item_other {
    grid-area: G;
    position: relative;
    /* margin-top: calc(-1 * var(--column-gap)); */
}
.section_superjob__item_other .section-item__name {
    font-size: 1rem;
    line-height: 1.25;
    text-align: left;
    font-weight: 400;
    font-style: italic;
    margin-top: 0;
}
/* .section_superjob__item_other:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, #ffffff 10%, #ffffff00);
} */
.section__item.section_superjob__item.section_superjob__item_other .section-item__name {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3rem;
}


@media (max-width: 800px) {
    .grid-wrapper_superjob {
        grid-template-areas: "A" "B" "C" "D" "F";
        grid-template-columns: 1fr;
    }
    .section_superjob__item_product,
    .section_superjob__item_other {
        display: none;
    }
    .section_superjob__item_subbrands .section-item__image {
        width: 70%;
        padding-bottom: 70%;
        margin: 0 auto;
    }
    .section_superjob__item_landings {
        padding: 0 2rem;
        overflow: hidden;
    }
}



/* IT Start */
.section_it_start__item {
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-gap: var(--column-gap);
    grid-template-areas: ". B B B B B B B B A A A A .";
    align-items: start;
    margin-top: var(--column-width);
    margin-bottom: var(--column-width);
    margin-top: 0;
    padding-top: 1em;
}

.section_it_start__item .section-item__description {
    grid-area: A;
    /* margin-left: var(--column-width); */
    /* padding: calc( var(--column-gap)) 0 calc(1.5 * var(--column-gap)) var(--column-gap); */
    height: fit-content;
}

.section_it_start__item .section-item__title {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 500;
    margin-bottom: .5rem;
}

.section_it_start__item .section-item__text {
    font-weight: 1rem;
    line-height: 1.333rem;  
    margin-bottom: .5rem;  
}

.section_it_start__item .section-item__image {
    grid-area: B;
    border-radius: 2px;
    overflow: hidden;
}

@media (max-width: 800px) {
    .section_it_start__item {
        grid-template-areas: "B" "A";
        grid-template-columns: 1fr;
        grid-gap: 0;
        margin: 2.5rem 0 1rem;
    }
    .section_it_start__item .section-item__description {
        padding: 1rem 2rem;
        width: 100%;
        box-shadow: none;
    }
}