.section_archive {
    margin: calc(1.5 * var(--column-gap)) 0;
}
.section_archive__header {
    background: #545B5D;
    color: #ffffff;
    position: relative;
}
.grid-wrapper_archive {
    background: linear-gradient(266.71deg, #E8F0F2 0%, #E3EBED 100%);
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-gap: var(--column-gap);
    grid-template-areas: ". A A A A B B B B C C C C .";
    padding: 3rem 0;
}
.section_archive__item:nth-child(1) {
    grid-area: A;
}
.section_archive__item:nth-child(2) {
    grid-area: B;
}
.section_archive__item:nth-child(3) {
    grid-area: C;
}

.section_archive .section-item__description {
    color: black;
    margin: .5rem 0;
}

@media (max-width: 800px) { 
    .grid-wrapper_archive {
        grid-template-areas: "A" "B" "C";
        grid-template-columns: 1fr;
    }
    .section_archive__item {
        padding: 0 2rem;
    }
}