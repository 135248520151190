.block_roles {
    padding: 3rem 0;
    display: grid;
    grid-template-areas: ". A A A A ." ". B C D E ." ". F F F F .";
    grid-template-columns: 1fr 3fr 3fr 3fr 3fr 1fr;
    grid-gap: var(--column-gap);
}
.block_roles__header { 
    grid-area: A; 
    font-weight: 600;
    margin-bottom: calc(var(--column-gap) * -1 + 1rem);
}
.block_roles__column:nth-child(2) { grid-area: B; }
.block_roles__column:nth-child(3) { grid-area: C; }
.block_roles__column:nth-child(4) { grid-area: D; }
.block_roles__column:nth-child(5) { grid-area: E; }

.block_roles__section {
    margin: 1rem 0;
}

.block_roles__section-title {
    font-weight: 600;
}

.block_roles__person {
    margin-top: .25rem;
}

.block_roles__caption { 
    grid-area: F; 
    font-style: italic;
    opacity: .5;
    margin-top: calc(var(--column-gap) * -1 + 1rem);
}

@media (max-width: 800px) {
    .route_superjob-identity__roles {
        grid-template-areas: "A" "B" "C" "D" "E" "F";
        grid-template-columns: 1fr;
        padding-left: var(--column-gap);
        padding-right: var(--column-gap);
        row-gap: 0;
    }
    .block_roles__header {
        margin-bottom: .5rem;
    }
    .block_roles__section {
        margin: .5rem 0;
    }
    .block_roles__caption {
        margin-top: .5rem;
    }
}