:root {
  --border-radius: 2px;
  --column-gap: 40px;
  --column-width: calc((100vw - var(--column-gap) * 13) / 14);
}

* {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  line-height: 1.2;
  overflow-x: hidden;
}

@media (min-width:1024px) {
  html {
    font-size: 18px;
  }
}
@media (min-width:1200px) {
  html {
    font-size: 20px;
  }
}
@media (min-width:1300px) {
  html {
    font-size: 22px;
  }
}
@media (min-width:1400px) {
  html {
    font-size: 24px;
  }
}
@media (min-width:1500px) {
  html {
    font-size: 26px;
  }
}

body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  transition: all .3s ease;
  text-decoration-thickness: from-font;
  text-decoration-color: #0075B766;
  text-decoration-skip-ink: none;
  text-decoration-line: underline;
  text-underline-offset: .15rem;
  color: #0075B7;
}

a:hover {
  color: #dd0000;
  text-decoration-color: #dd000066;
}

a.active, a.active:hover {
  color: #000000;
  text-decoration: none;
  cursor: default;
  font-weight: 500;
}

h2 sup {
  font-size: 70%;
}

img {
  max-width: 100%;
  display: block;
}

strong {
  font-weight: 600;
}

abbr {
  text-transform: lowercase;
  font-variant: small-caps;
  font-family: 'Fira Sans';
  letter-spacing: .06em;
}

/* Секция */
.section {
  width: 100%;
}

.section__header {
  max-width: max-content;
  font-size: 1.5rem;
  line-height: 1rem;
  padding: .7rem 1.2rem .9rem;
  font-variant: small-caps;
  font-weight: 500;
  margin: 0 auto -1.25rem;
  position: relative;
  letter-spacing: .06em;
  border-radius: 2px;
  transition: letter-spacing .3s ease;
  z-index: 100;
}
.section:hover .section__header {
  letter-spacing: .12em;
}
.section-header__date {
  font-size: 70%;
  line-height: 0;
}
.section__item .section-item__image {
  transition: all 3s ease;
}
a.section__item {
  text-decoration: none;
}
.section__item .section-item__name {
  border: none;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: calc(var(--column-gap) / 2);
  font-weight: 500;
}
a.section__item .section-item__name {
  text-decoration: underline;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 0.025em;
  text-underline-position: from-font;
  transition: all .3s ease;
  color: #0044ff;
  text-decoration-color: #0044ff66;
}
a.section__item:hover .section-item__name {
  color: #dd0000;
  text-decoration-color: #dd0000;
}
.section__item_main .section-item__name {
  line-height: 3rem;
  font-size: 2.5rem;
}
a.section__item_main .section-item__name {
  color: #ffffff;
  text-decoration-color: #ffffff66;
  max-width: 16em;
}
a.section__item_main:hover .section-item__name {
  text-decoration-color: #ffffff;
  color: #ffffff;
}
.section-item__date {
  font-weight: 1rem;
  line-height: 1.333rem;
  color: #86959D;
}
.section-item__caption {
  font-style: italic;
  font-size: 1rem;
  line-height: 1.25rem;
}


@media (max-width: 800px) {
  :root {
    --column-gap: 2rem;
  }
  .section__item_main .section-item__name {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}