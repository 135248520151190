.section_artlebedev {
    margin-top: calc(var(--column-gap) * 1.5);
}
.section_artlebedev__header {
    background-color: #F90710;
    color: #ffffff;
    position: relative;
    z-index: 2;
}
.grid-wrapper_artlebedev {
    display: grid;
    grid-template-areas: "A A A A A A A A A A A A A A" ". B B B B C C C C C C C C ." ". D D D D E E E E F F F F .";
    grid-gap: var(--column-gap);
    align-items: start;
    grid-template-columns: repeat(14, 1fr);
}
.section_artlebedev__item {
    align-self: start;
}
.section_artlebedev__item .section-item__image img {
    transition: all 3s ease;
}
.section_artlebedev__item:hover .section-item__image img {
    transform: scale(1.05);
}



.section_artlebedev__item_main {
    grid-area: A;
    position: relative;
    height: 0;
    padding-bottom: 55%;
    overflow: hidden;
}
.section_artlebedev-item_main__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.section_artlebedev-item_main__image img {
    transition: all 3s ease;
}
.section_artlebedev__item_main:hover .section_artlebedev-item_main__image img {
    transform: scale(1.05);
}
.section_artlebedev-item_main__image:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: linear-gradient(to top, #010c28aa, #00000000 60%);
}
.section-item_main__info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem calc(var(--column-gap) + var(--column-width)) 2rem;
    color: #ffffff;
}
.section-item_main__description {
    max-width: 40em;
}



.section_artlebedev__item_stickerpack {
    grid-area: B;
}
.section_artlebedev-item-stickerpack__image {
    position: relative;
    overflow: hidden;
    height: 39vw;
}

.section_artlebedev__item_pg {
    grid-area: C;
}
.section_artlebedev-item-pg__image {
    position: relative;
    overflow: hidden;
    height: 39vw;
}

.section_artlebedev__item_elets {
    grid-area: D;
}
.section_artlebedev-item-elets__image {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 135%;
}

.section_artlebedev__item_vdnkh {
    grid-area: E;
}
.section_artlebedev-item-vdnkh__image {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 135%;
}

.section_artlebedev .section-item__description {
    margin-top: .25rem;
    font-size: 1rem;
    line-height: 1.35rem;
    color: #000000;
}
.section_artlebedev .section-item_main__description {
    color: #ffffff;
    margin-top: 0.5rem;
}

.section_artlebedev__item_other {
    grid-area: F;
    display: block;
    margin: -.25rem;
    position: relative;
    height: 0;
    padding-bottom: calc( 100% + .5rem );
    background-color: #EAEFF4;
    border-radius: 50%;
    color: #000000;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    transition: all .3s ease;
}
.section_artlebedev__item_other:hover {
    color: #ffffff;
    background-color: #F90710;
}
.section_artlebedev-item_other__info {
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -5.5rem;
}
.section_artlebedev-item_other__number {
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 8rem;
    line-height: 7rem;
    margin-top: -1.2rem;
    font-weight: 500;
}

@media (max-width: 800px) { 
    .section_artlebedev {
        margin-top: 1rem;
    }
    .grid-wrapper_artlebedev {
        grid-template-areas: "A" "B" "C" "D" "E" "F";
        grid-template-columns: 1fr;
    }
    .section_artlebedev__item {
        padding: 0 2rem;
    }
    .section_artlebedev-item-stickerpack__image {
        height: 0;
        padding-bottom: 150%;
    }
    .section_artlebedev-item-pg__image {
        height: 0;
        padding-bottom: 70%;
    }
    .section_artlebedev__item_main {
        height: initial;
        padding: 0;
    }
    .section_artlebedev-item_main__image {
        position: static;
        overflow: hidden;
    }
    .section_artlebedev-item_main__image:after {
        display: none;
    }
    .section_artlebedev__item_main .section-item_main__info .section-item_main__name {
        color: #0044ff;
        text-decoration-color: #0044ff66;
    }
    .section_artlebedev__item_main:hover .section-item_main__info .section-item_main__name {
        color: #dd0000;
        text-decoration-color: #dd000066;
    }
    .section_artlebedev .section-item_main__info .section-item_main__description {
        color: black;
        margin-top: 0.25rem;
    }
    .section_artlebedev__item_other {
        padding: 0 2rem calc( 80% + 0.5rem );
        width: 80%;
        margin: -0.25rem auto;
    }
}