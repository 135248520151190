.top-navigation {
    margin: 1rem auto 2rem;
    padding: 0 2rem;
    max-width: 100%;
    overflow-x: auto;
}

.top-navigation__inner {
    width: fit-content;
    min-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    grid-gap: 1rem;
    column-gap: 1rem;
}

span.top-navigation__item {
    color: #86959d;
}