.section_hi {
    margin: 2rem 0;
    display: grid;
    grid-template-areas: ". A A A A B B B B B B B B .";
    grid-template-columns: repeat(14, 1fr);
    grid-gap: var(--column-gap);
    align-items: center;
}
.section_hi__avatar {
    grid-area: A;
    transform: scale(1.05);
}
.section_hi__text {
    grid-area: B;
    font-size: 1.5rem;
    line-height: 2rem;
}
.section_hi__text p {
    margin: .5rem 0;
}

@media (max-width: 800px) { 
    .section_hi {
        grid-template-areas: "A ." "B B";
        grid-template-columns: 1fr 1fr;
        padding: 0 2rem;
    }
}