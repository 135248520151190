.section_work {
    margin-top: calc(var(--column-gap) * 1.5);
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-template-areas: ". A A A A A A A A A A A A ." ". B B B B C C C C D D D D ." ". E E E E F F F F G G G G .";
    grid-gap: var(--column-gap);
}

.section_work__header {
    grid-area: A;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: -0.5rem;
}


.section_work__item {
    padding: .75rem 1rem;
    background: #666;
    border-radius: var(--border-radius);
    font-size: 1.5vw;
    line-height: 1.2;
    color: #FFFFFF;
    box-shadow: 0px 24px 48px -24px rgba(0, 2, 45, 0.5);
    height: 14vw;
    transition: all .3s ease;
    text-decoration: none;
}
.section_work__item:hover {
    /* transform: rotate3d(-.15, -.25, 0, 15deg); */
    transform: scale(1.025);
    color: #ffffff;
}
.section_work__item:nth-child(2n+1):hover {
    /* transform: rotate3d(-.15, .25, 0, 10deg); */
    transform: scale(1.05);
}
.section_work__item:nth-child(2) { grid-area: B }
.section_work__item:nth-child(3) { grid-area: C }
.section_work__item:nth-child(4) { grid-area: D }
.section_work__item:nth-child(5) { grid-area: E }
.section_work__item:nth-child(6) { grid-area: F }
.section_work__item:nth-child(7) { grid-area: G; }

.section_work__item_search {
    background: radial-gradient(32.79% 72.68% at 100% 0%, rgba(255, 122, 0, 0.2) 0%, rgba(255, 122, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(47.53deg, #1400FF 0%, #FF0099 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.25em;
}
.section_work__item_palindrome {
    background: black;
    background: radial-gradient(96.8% 100% at 100% 100%, #bdfe52 0%, #000000 100%) 
}
.section_work__item_superjob {
    background: radial-gradient(96.8% 100% at 100% 0%, #00D7B3 0%, #001F2D 100%) 
}
.section_work__item_geekbrains {
    background: linear-gradient(213.26deg, #021824 13.35%, #083047 86.34%);
}
.section_work__item_sber {
    background: radial-gradient(31.39% 56.28% at 4.63% 95.5%, #FEF600 0%, rgba(254, 246, 0, 0) 100%), radial-gradient(70.12% 77.63% at 95.5% 0%, #0087CD 22.88%, rgba(4, 156, 238, 0) 100%), radial-gradient(62.8% 109.77% at 98.64% 97.3%, #15A214 0%, rgba(21, 162, 20, 0) 100%), radial-gradient(49.87% 78.35% at 4.33% 0%, #3DE9AF 0%, rgba(63, 233, 175, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(55.85% 143.71% at 82.85% 54.88%, #43E9AA 2.91%, rgba(67, 233, 170, 0) 100%), linear-gradient(180deg, #4DC337 0%, #79D922 100%), #16A121;
}
.section_work__item_artlebedev {
    background: linear-gradient(213.26deg, #F8F9F9 13.35%, #E1E3E3 86.34%);
    color: black;
}
.section_work__item_artlebedev:hover {
    color: black;
}

.section_work__item-logo {
    height: 2em;
    margin-bottom: .5em;
}
.section_work__item-logo-palindrome {
    max-width: 80%;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

@media (max-width: 800px) { 
    .section_work {
        grid-template-areas: "A A" "B C" "D E" "F G";
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 0 2rem;
        width: 100%;
    }
    .section_work__item {
        height: 10rem;
        font-size: 1rem;
    }
    .section_work__item-logo {
        height: 1.5rem;
    }
    .section_work__header {
        line-height: 1.1;
        margin-bottom: .25rem;
    }
}
