.section_bit {
    margin: calc(1.5 * var(--column-gap)) 0;
}
.grid-wrapper_bit {
    display: grid;
    grid-template-areas: '. A A A A A A A A A B B B .';
    grid-template-columns: repeat(14, 1fr);
    grid-gap: var(--column-gap);
}

.section_bit__item_bit {
    grid-area: A;
}
.section-item_bit__text {
    margin-top: .5rem;
}
.section-item_bit__date {
    margin-top: .5rem;
}

.section_bit__item_pizza {
    grid-area: B;
    margin-top: -1rem;
}

@media (max-width: 800px) {
    .section_bit {
        margin: 0;
    }
    .grid-wrapper_bit {
        grid-template-areas: "A" "B";
        grid-template-columns: 1fr;
        padding: 1rem 2rem;
    }

    .section_bit__item_pizza img {
        width: 50%;
        margin: -1.5rem auto 0.5rem;
    }
    .section_bit__item_pizza {
        margin-top: 0;
    }
}