.section_instagram__item {
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-gap: var(--column-gap);
    grid-template-areas: "A A A A B B B B B B B B B B";
    align-items: center;
    overflow: hidden;
    padding: calc(1.5 * var(--column-width)) 0 var(--column-width);
}

.section_instagram__item .section-item__description {
    grid-area: A;
    margin-left: var(--column-width);
    padding: calc(1.5 * var(--column-gap)) var(--column-gap);
    box-shadow: 0px 24px 48px -24px rgb(0 2 45 / 33%);
    border-radius: 2px;
    background: #ffffff;
    height: fit-content;
    margin-right: calc(-1 * var(--column-width) - 2 * var(--column-gap));
    position: relative;
    z-index: 10;
}

.section_instagram__item .section-item__title {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 500;
    margin-bottom: .75rem;
}

.section_instagram__item .section-item__text {
    font-weight: 1rem;
    line-height: 1.333rem;  
    margin-bottom: .75rem;  
}

.section_instagram__item .section-item__image {
    grid-area: B;
    position: relative;
    height: 0;
    padding-bottom: 66%;
}
.section_instagram__item .section-item__image img {
    position: absolute;
    box-shadow: 0 0 0.15rem -0.05rem #030729;
    transition: all .3s ease;
}

.section_instagram__item .section-item__image img:nth-child(1) {
    width: 32%;
    top: 36%;
    left: 49%;
    z-index: 3;
    transform: rotate(-3deg);
}
.section_instagram__item:hover .section-item__image img:nth-child(1) {
    top: 36%;
    left: 54%;
    transform: rotate(1deg);
}

.section_instagram__item .section-item__image img:nth-child(2) {
    width: 28%;
    top: -1%;
    left: 43%;
    transform: rotate(12deg);
    z-index: 2;
}
.section_instagram__item:hover .section-item__image img:nth-child(2) {
    top: 0%;
    left: 46%;
    transform: rotate(14deg);
}

.section_instagram__item .section-item__image img:nth-child(3) {
    width: 48%;
    top: 4%;
    left: 6%;
    transform: rotate(-5deg);
    z-index: 1;
}
.section_instagram__item:hover .section-item__image img:nth-child(3) {
    top: 5%;
    left: 8%;
    transform: rotate(-3deg);
}

.section_instagram__item .section-item__image img:nth-child(4) {
    z-index: 4;
    width: 23%;
    top: 66%;
    left: 38%;
    transform: rotate(-20deg);
}
.section_instagram__item:hover .section-item__image img:nth-child(4) {
    top: 68%;
    left: 40%;
    transform: rotate(-15deg);
}

.section_instagram__item .section-item__image img:nth-child(5) {
    width: 30%;
    top: 55%;
    left: 75%;
    z-index: 6;
    transform: rotate(5deg);
    box-shadow: 0 0 0.15rem -0.05rem #03072966;
}
.section_instagram__item:hover .section-item__image img:nth-child(5) {
    top: 56%;
    left: 79%;
    transform: rotate(2deg);
}

.section_instagram__item .section-item__image img:nth-child(6) {
    width: 40%;
    top: 7%;
    left: 63%;
    z-index: 5;
    transform: rotate(-13deg);
}
.section_instagram__item:hover .section-item__image img:nth-child(6) {
    top: 10%;
    left: 70%;
    transform: rotate(-18deg);
}


@media (max-width: 800px) { 
    .section_instagram__item {
        grid-template-areas: "B" "A";
        grid-template-columns: 1fr;
        overflow: hidden;
        margin-bottom: 1rem;
    }
    .section_instagram__item .section-item__image {
        padding-bottom: 72%;
    }
    .section_instagram__item .section-item__image {
        overflow:visible;
        margin-top: 2rem;
    }
    .section_instagram__item .section-item__description {
        box-shadow: none;
        margin: -2rem 0 0;
        padding: 1rem 2rem;
        background: transparent;
    }
}