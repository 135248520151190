.block_image-variants {
    margin: 2rem 0;
}
.block_image-variants__header {
    font-size: 2rem;
    line-height: 1.8rem;
    font-feature-settings: "smcp";
    font-variant: small-caps;
    font-weight: 500;
    letter-spacing: .06em;
    text-align: center;
    margin-bottom: 1rem;
    padding: 0 1rem;
}

.block_image-variants__content {
    height: 0;
    position: relative;
    background: #123;
}
.block_image-variants__content-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
.block_image-variants__content-item_active {
    opacity: 1;
    z-index: 2;
}

.block_image-variants__content-item-link {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 0.7rem 1.2rem 0.9rem;
    font-feature-settings: "smcp";
    font-variant: small-caps;
    font-weight: 500;
    letter-spacing: .06em;
    border-radius: 2px;
    background: #ffffff;
    color: black;
    text-decoration: none;
}
.block_image-variants__content-item-link:not(:last-child) {
    margin-right: 1rem;
}
.block_image-variants__content-item-link:hover {
    color: #DD0000;
}

@media (max-width: 800px) {
    .block_image-variants__content-item {
        flex-direction: column;
    }
    .block_image-variants__content-item-link {
        font-size: 1rem;
        line-height: 1.2rem;
        padding: .5rem 1.2rem .6rem;
    }
    .block_image-variants__content-item-link:not(:last-child) {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}







.block_image-variants__controls {
    display: flex;
    padding: 1rem calc(var(--column-width) + var(--column-gap));
    overflow-x: scroll;
    width: fit-content;
    margin: 0 auto;
    max-width: 100%;
}
.block_image-variants__control-item {
    width: min-content;
}
.block_image-variants__control-item:not(:last-child) {
    margin-right: 1rem;
}

.block_image-variants__control-item-image {
    width: 6rem;
    height: 6rem;
    border-radius: .5rem;
    background-size: cover;
    transition: all .3s ease;
    position: relative;
    margin: 0 auto;
}
.block_image-variants__control-item-image:after {
    content: "";
    display: block;
    position: absolute;
    left: -.3rem;
    right: -.3rem;
    top: -.3rem;
    bottom: -.3rem;
    border: 0.2rem solid #00000000;
    pointer-events: none;
    border-radius: .7rem;
    transition: border-color .3s ease;
}

.block_image-variants__control-item:not(.block_image-variants__control-item_active) {
    cursor: pointer;
}

.block_image-variants__control-item:not(.block_image-variants__control-item_active):hover .block_image-variants__control-item-image:after {
    border-color: #00000033;
}

.block_image-variants__control-item_active .block_image-variants__control-item-image:after {
    border-color: #000000;
}

.block_image-variants__control-item-text {
    margin-top: .5rem;
    font-size: 1rem;
    line-height: 1.1rem;
    text-align: center;
    font-style: italic;
}

.block_image-variants__control-item_active .block_image-variants__control-item-text {
    font-weight: 500;
}

@media (max-width: 800px) {
    .block_image-variants__control-item-image {
        width: 5rem;
        height: 5rem;
        margin: 0 auto;
    }
}