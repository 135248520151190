.block_cover {
    width: 100%;
    padding-left: calc(var(--column-gap) + var(--column-width));
    padding-right: calc(var(--column-gap) + var(--column-width));
    margin: 2rem 0;
    background-repeat: no-repeat;
    background-size: cover;
}
.block_cover__title {
    font-size: 2rem;
    line-height: 2rem;
    padding: 0.6rem 2rem 0.8rem;
    font-feature-settings: "smcp";
    font-variant: small-caps;
    font-weight: 500;
    letter-spacing: .06em;
    border-radius: 2px;
    background: #ffffff;
}

.top-navigation + .block_cover {
    margin-top: -1rem;
}

@media (max-width: 800px) {
    .block_cover {
        margin: 1.5rem 0;
    }
}