.section_tgu__item {
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-gap: var(--column-gap);
    grid-template-areas: "A A A A B B B B B B B B B B";
    align-items: center;
    margin-top: var(--column-width);
    margin-bottom: var(--column-width);
}

.section_tgu__item .section-item__description {
    grid-area: A;
    margin-left: var(--column-width);
    padding: calc(1.5 * var(--column-gap)) var(--column-gap);
    box-shadow: 0px 24px 48px -24px rgb(0 2 45 / 33%);
    border-radius: 2px;
    background: #ffffff;
    height: fit-content;
    margin-right: calc(-1 * var(--column-width) - 2 * var(--column-gap));
    position: relative;
}

.section_tgu__item .section-item__title {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 500;
    margin-bottom: .5rem;
}

.section_tgu__item .section-item__text {
    font-weight: 1rem;
    line-height: 1.333rem;  
    margin-bottom: .5rem;  
}

.section_tgu__item .section-item__image {
    grid-area: B;
}

@media (max-width: 800px) {
    .section_tgu__item {
        grid-template-areas: "B" "A";
        grid-template-columns: 1fr;
        grid-gap: 0;
        margin: 2.5rem 0 1rem;
    }
    .section_tgu__item .section-item__description {
        padding: 1rem 2rem;
        width: 100%;
        box-shadow: none;
    }
}