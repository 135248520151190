.block_text {
    display: grid;
    grid-template-areas: '. A .';
    grid-template-columns: 2fr 10fr 2fr;
    grid-gap: var(--column-gap);
    margin: 2rem 0;
}
.block_text__inner {
    grid-area: A;
}
.block_text p {
    font-size: 1.5rem;
    line-height: 2rem;
}
.block_text p + p {
    margin-top: 1rem;
}
@media (max-width: 800px) {
    .block_text {
        display: block;
        padding: 0 var(--column-gap);
        margin: 1.5rem 0;
    }
    .block_text p {
        font-size: 1.2rem;
        line-height: 1.75rem;
    }
}