.section_ai {
    margin: 3rem 0 3rem;
    height: 30vw;
    background-size: 40rem;
    transition: all 3s ease;
    background-position: center;
    text-decoration: none;
    position: relative;
}

.section_ai a {
    text-decoration: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section_ai  .section_ai__header {
    background: white;
    color: black;
    text-decoration: none;
    margin-top: -0.6em;
}

.section_ai:hover {
    background-size: 41rem;
}

.section_ai:hover .section_ai__header {
    color: #DD0000;
}

@media (max-width: 800px) {
    .section_ai {
        background-size: 20rem;
        height: 50vw;
        margin: 2rem 0 2rem;
    }
    .section_ai:hover {
        background-size: 21rem;
    }
}
