.block_image-scroll {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 2rem 0;
}

.block_image-scroll img {
    position: relative;
    left: 0;
    max-width: initial;
}


@media (max-width: 800px) {
    .block_image-scroll .block_image__caption  {
        padding-left: var(--column-gap);
        padding-right: var(--column-gap);
    }
}