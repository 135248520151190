.grid-wrapper__sideprojects {
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-template-areas: ". A A A A A A B B B B B B .";
    grid-gap: var(--column-gap);
    align-items: stretch;
    margin: calc( 1.5 * var(--column-gap) ) 0;
}



/* Телеграммы */
.section_telegram {
    grid-area: A;
}
.section_telegram__header {
    background: #39B7FF;
    position: relative;
    z-index: 2;
}
.section_telegram__inner {
    background: #DBF2FF;
    height: calc(100% - 1.25rem);
    border-radius: 2px;
    padding: 3.5rem 1.5rem;
    position: relative;
    overflow: visible;
}
.section_telegram__channel {
    display: grid;
    grid-template-areas: "A B";
    grid-template-columns: 1fr 3fr;
    grid-gap: .5rem 1rem;
    text-decoration: none;
    margin-bottom: 2rem;
    align-items: start;
    justify-items: start;
}
.section_telegram__channel-image {
    grid-area: A;
    transition: all .3s ease;
}
.section_telegram__channel-info {
    grid-area: B;
    align-self: center;
}
.section_telegram__channel:hover .section_telegram__channel-image {
    filter: brightness(1.1) saturate(1.1) contrast(1.1);
}
.section_telegram__channel-name {
    text-decoration: underline;
    text-underline-offset: 0.1em;
    text-decoration-thickness: 0.025em;
    text-underline-position: from-font;
    transition: all .3s ease;
    color: #0044ff;
    text-decoration-color: #0044ff66;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
    align-self: end;
    margin-bottom: .5rem;
}
.section_telegram__channel:hover .section_telegram__channel-name {
    color: #dd0000;
    text-decoration-color: #dd0000;    
}
.section_telegram__channel-description {
    grid-area: C;
    color: #000;
}
.section_telegram__plane {
    position: absolute;
    height: 33%;
    bottom: -7%;
    left: 10%;
    transition: all .6s ease;
}
.section_telegram:hover .section_telegram__plane {
    bottom: 2%;
    left: 30%;
    height: 27%;
    filter: blur(2px);
    opacity: .9;
}


/* Пересечение */
.section_intersection {
    grid-area: B;
    text-decoration: none;
    color: #000;
}
.section_intersection:hover {
    color: #000;
}
.section_intersection__header {
    background: linear-gradient(227.28deg, #24FF00 -51.06%, #FFE600 106.57%);
}
.section_intersection__inner {
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #EFF3F5 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    padding: 2rem 0;
    border-radius: 2px;
}
.section_intersection__text {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    line-height: 1.25rem;
    font-size: 1rem;
}
.section_intersection__image {
    margin-top: 2rem;
    max-width:80%;
    margin:2rem auto;
    
}
.section_intersection:hover .section_intersection__image-sign {
    transform: scale(1.1);
}

.section_intersection__image-sign {
    position: relative;
    margin: 0 auto;
    width: fit-content;
    transition: all .8s ease;
    transform: scale(.9);
}
.section_intersection__image-sign img {
    transition: all .8s ease;
}
.section_intersection__image-sign img:nth-child(1) {
    position: absolute;
    transform: rotate(45deg);
}
.section_intersection:hover .section_intersection__image-sign img:nth-child(1) {
    transform: rotate(-45deg);
}
.section_intersection__image-sign img:nth-child(2) {
    position: relative;
}
.section_intersection:hover .section_intersection__image-sign img:nth-child(2) {
    transform: rotate(90deg);
}
.section_intersection__image-sign img:nth-child(3) {
    position: absolute;
    top: 14.75%;
    left: 14.75%;
    transform: rotate(45deg);
}
.section_intersection:hover .section_intersection__image-sign img:nth-child(3) {
    transform: rotate(-45deg);
}
.section_intersection__image-sign img:nth-child(4) {
    position: absolute;
    top: 20.5%;
    left: 20.5%;
}
.section_intersection:hover .section_intersection__image-sign img:nth-child(4) {
    transform: rotate(180deg);
}
.section_intersection__image-sign img:nth-child(5) {
    position: absolute;
    top: 30%;
    left: 30%;
}
.section_intersection:hover .section_intersection__image-sign img:nth-child(5) {
    transform: rotate(360deg);
}
.section_intersection__image-text {
    width: fit-content;
    margin: 2.5rem auto;
}


@media (max-width: 800px) {
    .grid-wrapper__sideprojects {
        grid-template-areas: "A" "B";
        grid-template-columns: 1fr;
        margin-top: 0;
        grid-gap: calc(1.5 * var(--column-gap));
        margin-bottom: 2rem 0 1.5rem
    }
    .section_intersection__image-sign {
        transform: scale(.8);
    }
    .section_intersection:hover .section_intersection__image-sign {
        transform: scale(.9);
    }
}