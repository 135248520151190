.footer {
    padding: var(--column-gap) calc(var(--column-width) + var(--column-gap));
    text-align: center;
}

.footer p {
    padding: .1em 0;
}

.footer_dark {
    background: #08121a;
    color: #fff;
}

.footer_dark a {
    color: #ffffff;
    text-decoration-color: #ffffff66;
}

.footer_dark a:hover {
    color: #ffffff;
    text-decoration-color: #ffffff;
}