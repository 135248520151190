.block_image {
    width: 100%;
    padding-left: calc(var(--column-gap) + var(--column-width));
    padding-right: calc(var(--column-gap) + var(--column-width));
    margin: 2rem 0;
}
.block_image__img {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
}
.block_image__img img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.block_image_fullscreen {
    padding-left: 0;
    padding-right: 0;
}
.block_image__caption {
    margin-top: .75rem;
    padding-left: 50%;
    font-style: italic;
    width: 90%;
}

@media (max-width: 800px) {
    .block_image {
        margin: 1.5rem 0;
    }
    .block_image:not(.block_image_fullscreen) {
        padding-left: var(--column-gap);
        padding-right: var(--column-gap);
    }
    .block_image__caption {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        line-height: 1.4rem;
    }
    .block_image_fullscreen .block_image__caption  {
        padding-left: var(--column-gap);
        padding-right: var(--column-gap);
    }
}