.section_sber {
    margin-top: calc(var(--column-gap) * 1.5);
}
.section_sber__header {
    background-color: #07A934;
    color: #ffffff;
    position: relative;
    z-index: 2;
}
.grid-wrapper_sber {
    display: grid;
    grid-template-areas: "A A A A A A A A A A A A A A" ". B B B B B B C C C C C C .";
    grid-gap: var(--column-gap);
    align-items: start;
    grid-template-columns: repeat(14, 1fr);
}
.section_sber__item {
    align-self: start;
}



.section_sber__item_main {
    grid-area: A;
    position: relative;
    height: 0;
    padding-bottom: 55%;
    overflow: hidden;
}
.section_sber-item_main__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.section_sber-item_main__image img {
    transition: all 3s ease;
}
.section_sber__item_main:hover .section_sber-item_main__image img {
    transform: scale(1.05);
}
.section-item_main__info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem calc(var(--column-gap) + var(--column-width)) 2rem;
    color: #ffffff;
}
.section-item_main__description {
    max-width: 40em;
}

.section_sber__item_navigation {
    grid-area: B;
}

.section_sber__item_guides {
    grid-area: C;
}

.section_sber .section-item__description {
    margin-top: .25rem;
    font-size: 1rem;
    line-height: 1.35rem;
}

@media (max-width: 800px) {
    .section_sber {
        margin: 1.5rem 0;
    }
    .grid-wrapper_sber {
        grid-template-areas: "A" "B" "C";
        grid-template-columns: 1fr;
    }
    .section_sber__item_navigation {
        margin: 0 2rem;
    }

    .section_sber__item_guides {
        margin: 0 2rem;
    }
    .section_sber__item_main {
        padding: 0;
        height: max-content;
    }
    .section_sber-item_main__image {
        position: static;
    }
    .section-item_main__info {
        position: static;
        color: black;
        padding: 0 2rem;
    }
}
