.route_reunion {
    overflow: hidden;
}

.section_reunion__header {
    color: white;
    background: #003ca7;
    margin-bottom: 1rem;
}

.route_reunion__video {
    background: #003ca7;
    display: block;
    overflow: hidden;
}

.route_reunion__video video {
    display: block;
    margin: -5vw auto -10vw;
    width: 100%;
    max-width: 40vw;
}
.route_reunion__roles {
    background: #003ca7;
    color: #ffffff;
    position: relative;
}

.route_reunion__roles a {
    color: #ffffff;
    text-decoration-color: #ffffff66;
}
.route_reunion__roles a:hover {
    text-decoration-color: #ffffff;
}

.reunion-news {
    position: relative;
    height: 80vw;
    /* overflow-x: hidden; */
    width: 100vw;
}
.reunion-news__image-item {
    position: absolute;
    box-shadow: 0 2vw 6vw -3vw rgba(6, 26, 62, .8);
    border-radius: 2px;
}
.reunion-news__image-item-1 {
    width: 30vw;
    left: 73vw;
    top: 42vw;
    transform: rotate(5deg);
}
.reunion-news__image-item-2 {
    width: 54vw;
    left: 25vw;
    top: 26vw;
    transform: rotate(-2deg);
}
.reunion-news__image-item-3 {
    width: 70vw;
    right: 0;
    top: 0;
    transform: rotate(2deg);
}
.reunion-news__image-item-4 {
    width: 40vw;
    bottom: 1vw;
    left: 40vw;
    transform: rotate(5deg);
}
.reunion-news__image-item-5 {
    width: 50vw;
    transform: rotate(-2deg);
    left: -5vw;
    top: 2vw;
}
.reunion-news__image-item-6 {
    width: 60vw;
    transform: rotate(-5deg);
    bottom: -4vw;
    left: 2vw;
}